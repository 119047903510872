import * as Sentry from "@sentry/astro";

Sentry.init({
  dsn: "https://485040f334292dc42c1b62f4e61d1546@o227862.ingest.us.sentry.io/4507344347332608",
  debug: false,
  environment: "live",
  release: "master#2536",
  tracesSampleRate: 0.1,
  sampleRate: 0.2,
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  replaysSessionSampleRate: 0.025,
  replaysOnErrorSampleRate: 0.1,
});